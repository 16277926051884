<template>
    <div class="inner-section">
      <card>
        <template v-slot:searchHeaderTitle>
          <h4 class="card-title">{{ $t('tpm_report.trainer_performance_report') }} {{ $t('globalTrans.search') }}</h4>
        </template>
        <template v-slot:searchBody>
          <b-row>
            <b-col lg="12" sm="12">
              <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                <b-form @submit.prevent="handleSubmit(searchData)" @reset.prevent="reset" >
                  <b-row>
                    <b-col lg="6" sm="6">
                      <ValidationProvider name="Fiscal Year"  vid="fiscal_year_id" rules="required|min_value:1">
                        <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="fiscal_year_id"
                            slot-scope="{ valid, errors }"
                        >
                          <template v-slot:label>
                            {{$t('elearning_config.fiscal_year')}} <span class="text-danger">*</span>
                          </template>
                          <b-form-select
                              plain
                              v-model="search.fiscal_year_id"
                              :options="fiscalYearList"
                              id="fiscal_year_id"
                              :state="errors[0] ? false : (valid ? true : null)"
                          >
                            <template v-slot:first>
                              <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                            </template>
                          </b-form-select>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <b-col lg="6" sm="6">
                    <ValidationProvider name="Training Type"  vid="training_type_id" rules="required|min_value:1">
                      <b-form-group
                          class="row"
                          label-cols-sm="4"
                          label-for="training_type_id"
                          slot-scope="{ valid, errors }"
                      >
                        <template v-slot:label>
                          {{$t('elearning_config.training_type')}} <span class="text-danger">*</span>
                        </template>
                        <b-form-select
                          plain
                          v-model="search.training_type_id"
                          :options="trainingTypeList"
                          id="training_type_id"
                          :state="errors[0] ? false : (valid ? true : null)"
                          >
                          <template v-slot:first>
                              <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                          </template>
                          </b-form-select>
                          <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                    </b-col>
                    <b-col lg="6" sm="6">
                      <ValidationProvider name="Training Category" vid="training_category_id" rules="required|min_value:1">
                        <b-form-group
                          class="row"
                          label-cols-sm="4"
                          label-for="training_category_id"
                          slot-scope="{ valid, errors }"
                        >
                          <template v-slot:label>
                            {{$t('elearning_config.training_category')}} <span class="text-danger">*</span>
                          </template>
                          <b-form-select
                            plain
                            v-model="search.training_category_id"
                            :options="trainingCategoryList"
                            id="training_category_id"
                            :state="errors[0] ? false : (valid ? true : null)"
                            >
                            <template v-slot:first>
                                <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                            </template>
                            </b-form-select>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <b-col lg="6" sm="6">
                      <ValidationProvider name="Training Title" vid="training_title_id" rules="required|min_value:1">
                        <b-form-group
                          class="row"
                          label-cols-sm="4"
                          label-for="training_title_id"
                          slot-scope="{ valid, errors }"
                        >
                          <template v-slot:label>
                            {{$t('elearning_config.training_title')}} <span class="text-danger">*</span>
                          </template>
                          <b-form-select
                            plain
                            v-model="search.training_title_id"
                            :options="trainingTitleList"
                            id="training_title_id"
                            :state="errors[0] ? false : (valid ? true : null)"
                            >
                            <template v-slot:first>
                                <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                            </template>
                            </b-form-select>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <b-col lg="6" sm="6">
                      <ValidationProvider name="Trainer"  vid="personal_info_id"  rules="required|min_value:1">
                        <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="personal_info_id"
                            slot-scope="{ valid, errors }"
                        >
                          <template v-slot:label>
                            {{ $t('externalTraining.trainer_name') }} <span class="text-danger">*</span>
                          </template>
                          <b-form-select
                              plain
                              v-model="search.personal_info_id"
                              :options="trainerList"
                              id="personal_info_id"
                              :state="errors[0] ? false : (valid ? true : null)"
                          >
                            <template v-slot:first>
                              <b-form-select-option :value="0">{{ isLoading ? $t('globalTrans.loading') + '....' : $t('globalTrans.select') }}</b-form-select-option>
                            </template>
                          </b-form-select>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <b-col cols="6">
                      <b-button size="sm" type="submit" variant="primary" class="">
                        <i class="ri-search-line"></i> {{ $t('globalTrans.search') }}
                      </b-button>
                    </b-col>
                  </b-row>
                </b-form>
              </ValidationObserver>
            </b-col>
          </b-row>
        </template>
      </card>
      <b-row>
        <b-col md="12" v-if="showData">
          <body-card>
            <template v-slot:headerTitle>
              <h4 class="card-title">{{ $t('tpm_report.trainer_performance_report') }}</h4>
            </template>
            <template v-slot:headerAction>
              <b-button class="btn_add_new" @click="pdfExport">
                <i class="fas fa-print"></i>{{ $t('globalTrans.print') }}
              </b-button>
            </template>
            <template v-slot:body>
              <b-overlay :show="loading">
                <b-row mt-5 >
                  <b-col md="12" class="table-responsive" v-if="datas.length>0">
                    <div style="border: 2px solid;margin:10px;padding:10px">
                      <b-row>
                        <b-col>
                          <list-report-head :base-url="trainingElearningServiceBaseUrl" uri="/config/report-head/detail" :org-id="12">
                            <template v-slot:projectNameSlot>
                              {{ }}
                            </template>
                            {{ $t('tpm_report.trainer_performance_report') }}
                          </list-report-head>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col>
                          <div class="text-center">
                            <table style="width:100%;color:black;">
                              <tr v-if="search.fiscal_year">
                                <td align="right" style="width:45%">{{ $t('elearning_config.fiscal_year') }}</td>
                                <td align="center" style="width:5%">:</td>
                                <td align="left" style="width:50%">{{ ($i18n.locale==='bn') ? search.fiscal_year_bn : search.fiscal_year }}</td>
                              </tr>
                              <tr v-if="search.org_name">
                                <td align="right" style="width:45%">{{ $t('elearning_config.organization') }}</td>
                                <td align="center" style="width:5%">:</td>
                                <td align="left" style="width:50%">{{ ($i18n.locale==='bn') ? search.org_name_bn : search.org_name }}</td>
                              </tr>
                              <tr v-if="search.training_type">
                                <td align="right" style="width:45%">{{ $t('elearning_config.training_type') }}</td>
                                <td align="center" style="width:5%">:</td>
                                <td align="left" style="width:50%">{{ ($i18n.locale==='bn') ? search.training_type_bn : search.training_type }}</td>
                              </tr>
                              <tr v-if="search.training_category">
                                <td align="right" style="width:45%">{{ $t('elearning_config.training_category') }}</td>
                                <td align="center" style="width:5%">:</td>
                                <td align="left" style="width:50%">{{ ($i18n.locale==='bn') ? search.training_category_bn : search.training_category }}</td>
                              </tr>
                              <tr v-if="search.training_title">
                                <td align="right" style="width:45%">{{ $t('elearning_config.training_title') }}</td>
                                <td align="center" style="width:5%">:</td>
                                <td align="left" style="width:50%">{{ ($i18n.locale==='bn') ? search.training_title_bn : search.training_title }}</td>
                              </tr>
                              <tr v-if="search.trainer_name">
                                <td align="right" style="width:45%">{{ $t('externalTraining.trainer_name') }}</td>
                                <td align="center" style="width:5%">:</td>
                                <td align="left" style="width:50%">{{ ($i18n.locale==='bn') ? search.trainer_name_bn : search.trainer_name }}</td>
                              </tr>
                            </table>
                          </div>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col>
                          <b-table-simple class="tg mt-3" bordered hover small caption-top responsive>
                            <b-thead>
                              <b-tr>
                                <b-th style="width:7%" class="text-center">{{ $t('globalTrans.sl_no') }}</b-th>
                                <b-th style="width:10%" class="text-center">{{ $t('elearning_config.evaluation_name') }}</b-th>
                                <b-th class="text-center">{{ $t('elearning_config.title') }}</b-th>
                              </b-tr>
                            </b-thead>
                            <b-tbody>
                                <template v-if="datas.length>0">
                                  <template v-for="(info, index) in datas">
                                    <b-tr :key="index">
                                      <b-td class="text-center">{{ $n(index + 1, { useGrouping: false }) }}</b-td>
                                      <b-td class="text-center" style="width:25%">{{ ($i18n.locale==='bn') ? info.trainer_evaluation_name_bn : info.trainer_evaluation_name }}</b-td>
                                      <b-td class="text-center">{{ ($i18n.locale==='bn') ? info.grading_bn : info.grading }}</b-td>
                                    </b-tr>
                                  </template>
                                    <b-tr>
                                      <b-th style="width:7%" class="text-center" colspan="2">{{ $t('tpm_report.average_assessment') }}</b-th>
                                      <b-th class="text-center">{{ grading_total }}</b-th>
                                    </b-tr>
                                </template>
                              <template v-else>
                                <b-tr>
                                  <b-td align="center" colspan="5">{{ $t('globalTrans.noDataFound') }}</b-td>
                                </b-tr>
                              </template>
                            </b-tbody>
                          </b-table-simple>
                        </b-col>
                      </b-row>
                    </div>
                  </b-col>
                </b-row>
              </b-overlay>
            </template>
          </body-card>>
        </b-col>
      </b-row>
    </div>
</template>
<script>

import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { trainingElearningServiceBaseUrl } from '@/config/api_config'
import { trainerPerformanceReport } from '../../api/routes'
import flatpickr from 'flatpickr'
import ListReportHead from '@/components/custom/ListReportHeadTraining.vue'
import ExportPdf from './export_pdf_details'
import excel from 'vue-excel-export'
import Vue from 'vue'
import Store from '@/store'
Vue.use(excel)

export default {
    props: ['id'],
    components: {
        ValidationObserver,
        ValidationProvider,
        ListReportHead
    },
    created () {
      this.search.fiscal_year_id = this.$store.state.currentFiscalYearId
        // this.search = Object.assign({}, this.search, {
        //     org_id: this.$store.state.dataFilters.orgId,
        //     fiscal_year_id: this.$store.state.TrainingElearning.currentFiscalYearId
        // })
    },
    data () {
        return {
          search: {
            org_id: 0,
            training_type_id: 0,
            training_category_id: 0,
            training_title_id: 0,
            fiscal_year_id: 0,
            personal_info_id: 0
          },
          trainingCategoryList: [],
          trainingTitleList: [],
          trainingElearningServiceBaseUrl: trainingElearningServiceBaseUrl,
          datas: [],
          disciplinaryAction: {
            disciplinary_action: '',
            disciplinary_action_bn: ''
          },
          showData: false,
          grading_total: '',
          grading_total_bn: '',
          trainerList: [],
          isLoading: false
        }
    },
    mounted () {
    flatpickr('.fromDate', {})
  },
    computed: {
      orgList: function () {
        return this.$store.state.CommonService.commonObj.componentOrgList.filter(item => item.status === 1)
      },
      trainingTypeList: function () {
        return this.$store.state.TrainingElearning.commonObj.trainingTypeList.filter(item => item.status === 1)
      },
      fiscalYearList: function () {
        return this.$store.state.CommonService.commonObj.fiscalYearList.filter(item => item.status === 1)
      },
      trainerEvaluationList: function () {
        return this.$store.state.TrainingElearning.commonObj.trainerEvaluationList.filter(item => item.status === 1)
      },
      personalInfoList: function () {
        return this.$store.state.TrainingElearning.commonObj.personalInfoList.filter(item => item.status === 3)
      },
      loading () {
        return this.$store.state.commonObj.loading
      }
    },
    watch: {
      'search.training_type_id': function (newValue) {
        this.trainingCategoryList = this.getCategoryList(newValue)
      },
      'search.training_category_id': function (newValue) {
        this.trainingTitleList = this.getTrainingTitleList(newValue)
      },
      'search.training_title_id': function (newValue) {
        this.getTrainerList(newValue)
      }
    },
    methods: {
      getCategoryList (typeId) {
        const trainingCategoryList = this.$store.state.TrainingElearning.commonObj.trainingCategoryList.filter(item => item.status === 1)
        if (typeId) {
          return trainingCategoryList.filter(item => item.training_type_id === typeId)
        }
        return trainingCategoryList
      },
      getTrainingTitleList (categoryId) {
        const trainingTitleList = this.$store.state.TrainingElearning.commonObj.trainingTitleList.filter(item => item.status === 1)
        if (categoryId) {
          return trainingTitleList.filter(item => item.training_category_id === categoryId)
        }
        return trainingTitleList
      },
      async getTrainerList (trainerTitleId) {
        if (trainerTitleId) {
          this.isLoading = true
          const result = await RestApi.getData(trainingElearningServiceBaseUrl, '/iabm/training-applications/trainer-list/' + trainerTitleId)
          if (result.success) {
            this.trainerList = result.data.map(item => {
              return { value: item.value, text: this.$i18n.locale === 'en' ? item.text_en : item.text_bn }
            })
          } else {
            this.trainerList = []
          }
          this.isLoading = false
        }
      },
      pdfExport () {
          const reportTitle = this.$t('tpm_report.trainer_performance_report')
          ExportPdf.exportPdfDetails(trainingElearningServiceBaseUrl, '/config/report-head/detail', 12, reportTitle, this.datas, this, this.search, this.grading_total, this.grading_total_bn)
      },
      async searchData () {
        Store.commit('mutateCommonProperties', {
          loading: true
        })
        this.showData = true
        const orgObj = this.$store.state.CommonService.commonObj.orgProfileList.find(item => item.value === this.search.org_id)
        this.search.org_name = typeof orgObj !== 'undefined' ? orgObj.text_en : ''
        this.search.org_name_bn = typeof orgObj !== 'undefined' ? orgObj.text_bn : ''

        const fiscalYearObj = this.$store.state.CommonService.commonObj.fiscalYearList.find(item => item.value === this.search.fiscal_year_id)
        this.search.fiscal_year = typeof fiscalYearObj !== 'undefined' ? fiscalYearObj.text_en : ''
        this.search.fiscal_year_bn = typeof fiscalYearObj !== 'undefined' ? fiscalYearObj.text_bn : ''

        const trainingTypeObj = this.$store.state.TrainingElearning.commonObj.trainingTypeList.find(item => item.value === this.search.training_type_id)
        this.search.training_type = typeof trainingTypeObj !== 'undefined' ? trainingTypeObj.text_en : ''
        this.search.training_type_bn = typeof trainingTypeObj !== 'undefined' ? trainingTypeObj.text_bn : ''

        const trainingCategoryListObj = this.$store.state.TrainingElearning.commonObj.trainingCategoryList.find(item => item.value === this.search.training_category_id)
        this.search.training_category = typeof trainingCategoryListObj !== 'undefined' ? trainingCategoryListObj.text_en : ''
        this.search.training_category_bn = typeof trainingCategoryListObj !== 'undefined' ? trainingCategoryListObj.text_bn : ''

        const trainingTitleListObj = this.$store.state.TrainingElearning.commonObj.trainingTitleList.find(item => item.value === this.search.training_title_id)
        this.search.training_title = typeof trainingTitleListObj !== 'undefined' ? trainingTitleListObj.text_en : ''
        this.search.training_title_bn = typeof trainingTitleListObj !== 'undefined' ? trainingTitleListObj.text_bn : ''

        const personalInfoObj = this.personalInfoList.find(item => item.value === this.search.personal_info_id)
        this.search.trainer_name = typeof personalInfoObj !== 'undefined' ? personalInfoObj.text_en : ''
        this.search.trainer_name_bn = typeof personalInfoObj !== 'undefined' ? personalInfoObj.text_bn : ''

        const result = await RestApi.getData(trainingElearningServiceBaseUrl, trainerPerformanceReport, this.search)
        if (result.success) {
          Store.commit('mutateCommonProperties', {
            loading: false
          })
          if (result.data) {
            this.datas = this.getCustomDataList(result.data)
            const gradingStatus = this.$store.state.TrainingElearning.commonObj.gradingStatus
            gradingStatus.map(grading => {
              if (parseFloat(grading.below_number) <= parseFloat(result.total) && parseFloat(grading.above_number) >= parseFloat(result.total)) {
                this.grading_total = grading.text_en
                this.grading_total_bn = grading.text_en
              }
            })
          }
        } else {
          this.datas = []
          this.grading_total = ''
          this.grading_total_bn = ''
        }
      },
      getCustomDataList (data) {
        const listData = data.map(item => {
          const gradingStatus = this.$store.state.TrainingElearning.commonObj.gradingStatus
          const customItem = {
            grading: null,
            grading_bn: null
          }
          gradingStatus.map(grading => {
            if (parseFloat(grading.below_number) <= parseFloat(item.attain_marks) && parseFloat(grading.above_number) >= parseFloat(item.attain_marks)) {
              customItem.grading = grading.text_en
              customItem.grading_bn = grading.text_en
            }
          })
          return Object.assign({}, item, customItem)
        })
        return listData
      }
    }
}
</script>
